import { TrackJS } from "trackjs"

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    })
  } else {
    TrackJS.track(`[GTM-ERROR]: pageview tracking URL: ${url}`)
  }
}

export function gtag(data: any) {
  // We expect an error here as typescript does not know
  // we've added dataLayer to window when initializing GTM
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
  window.dataLayer.push(data)
}

export function addEmailToDataLayer(email: string) {
  gtag({ email })
}
